<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch"
              >
                <v-select
                  v-model="branch"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="branchoption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Employee Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Employee Name"
              >
                <v-select
                  v-model="employeeid"
                  label="fullname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="employeeoption"
                >
                <template
                    #option="{ fullname, profile_image, username, color }"
                  >
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-' + color">
                      {{ fullname }} ({{ username }})</span
                    >
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Date"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Month</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="month"
              >
                <v-select
                  v-model="month"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="[
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12',
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Year</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="year"
              >
                <v-select
                  v-model="year"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="year"
                  placeholder="None"
                  :options="[
                    '2013',
                    '2014',
                    '2015',
                    '2016',
                    '2017',
                    '2018',
                    '2019',
                    '2020',
                    '2021',
                    '2022',
                    '2023',
                    '2024',
                    '2025',
                    '2026',
                    '2027',
                    '2028',
                    '2029',
                    '2030',
                    '2031',
                    '2032',
                    '2033',
                    '2034',
                    '2035',
                    '2036',
                    '2037',
                    '2038',
                    '2039',
                    '2040',
                    '2041',
                    '2042',
                    '2043',
                    '2044',
                    '2045',
                    '2046',
                    '2047',
                    '2048',
                    '2049',
                    '2050',
                    '2051',
                    '2052',
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="Ratings" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col>
            <tr>
              <td>Job Knowledge</td>
              <b-form-group class="pl-3">
                <b-form-radio-group
                  v-model="jobKnowlegde"
                  :options="options"
                  class="demo-inline-spacing"
                  name="jobKnowlegde"
                />
              </b-form-group>
            </tr>
            <tr>
              <td>Work Quality</td>
              <b-form-group class="pl-3">
                <b-form-radio-group
                  v-model="workQuality"
                  :options="options"
                  class="demo-inline-spacing"
                  name="workQuality"
                />
              </b-form-group>
            </tr>
            <tr>
              <td>Attendance/Punctuality</td>
              <b-form-group class="pl-3">
                <b-form-radio-group
                  v-model="attendancePunctuality"
                  :options="options"
                  class="demo-inline-spacing"
                  name="attendancePunctuality"
                />
              </b-form-group>
            </tr>
            <tr>
              <td>Initiative</td>
              <b-form-group class="pl-3">
                <b-form-radio-group
                  v-model="intiative"
                  :options="options"
                  class="demo-inline-spacing"
                  name="intiative"
                />
              </b-form-group>
            </tr>
            <tr>
              <td>Communication/Listening Skills</td>
              <b-form-group class="pl-3">
                <b-form-radio-group
                  v-model="communicationListeningSkills"
                  :options="options"
                  class="demo-inline-spacing"
                  name="communicationListeningSkills"
                />
              </b-form-group>
            </tr>
            <tr>
              <td>GOALS</td>
              <b-form-group class="pl-3">
                <b-form-radio-group
                  v-model="goals"
                  :options="options"
                  class="demo-inline-spacing"
                  name="goals"
                />
              </b-form-group>
            </tr>
            <tr>
              <td>MANAGEMENT RATING</td>
              <b-form-group class="pl-3">
                <b-form-radio-group
                  v-model="managmentRating"
                  :options="options"
                  class="demo-inline-spacing"
                  name="managmentRating"
                />
              </b-form-group>
            </tr>
          </b-col>
          <b-col md="12">
            <b-card-code title="Evaluation" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>ADDITIONAL COMMENTS </label>
              <b-form-input
                v-model="additionalcomment"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              :disabled="flag"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BFormRadioGroup,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from "moment";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormRadioGroup,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BAvatar

  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      branch: "",
      jobKnowlegde: "Poor",
      workQuality: "Poor",
      attendancePunctuality: "Poor",
      intiative: "Poor",
      communicationListeningSkills: "Poor",
      goals: "Poor",
      managmentRating: "Poor",
      branchoption: [],
      employeeid: "",
      employeeoption: [],
      date: moment(new Date()).format("DD/MM/yyyy"),
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      month: "",
      year: "",
      additionalcomment: "",
      ifEdit: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      options: [
        { text: "Poor", value: "Poor" },
        { text: "Fair", value: "Fair" },
        { text: "Satisfactory", value: "Satisfactory" },
        { text: "Good", value: "Good" },
        { text: "Excellent", value: "Excellent" },
      ],
      flag: false,
      imagePath: process.env.VUE_APP_IMAGE_PATH,

    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Employee Performance Management") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/hr/employeeperformancemanagement");
            }
          } else if (item.add !== 1) {
            this.$router.push("/hr/employeeperformancemanagement");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
     getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getperfomanceById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.branch = item.branch ;
        this.employeeid = item.employee;
        this.date = item.date;
        this.month = item.month;
        this.year = item.year;
        this.jobKnowlegde = item.jobknowledge;
        this.workQuality = item.workquality;
        this.attendancePunctuality = item.attendance;
        this.intiative = item.initiative;
        this.communicationListeningSkills = item.communication;
        this.goals = item.goals;
        this.managmentRating = item.managementrating;
        this.additionalcomment = item.additionalcomment;
      });
    },
    init() {
      this.getBranch();
      this.getUser();
    },
    async getBranch(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/branch`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.branchoption = response.data.data.data;
          this.branchoption.map((item) => {
            if (item.id == id) {
              this.branch = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },

    async getUser(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeoption = response.data.data;
          this.employeeoption.map((item) => {
            if (item.id == id) {
              this.employeeid = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/hr/employeeperformancemanagement");
    },
    submitForm(e) {
      const data = {
        branch: this.branch.id,
        employeeid: this.employeeid.id,
        date: this.date,
        month: this.month,
        year: this.year,

        jobknowledge: this.jobKnowlegde,
        workquality: this.workQuality,
        attendance: this.attendancePunctuality,
        initiative: this.intiative,
        communication: this.communicationListeningSkills,
        goals: this.goals,
        managementrating: this.managmentRating,
        additionalcomment: this.additionalcomment,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/perfomance/${this.$route.params.id}`
              : `${baseApi}/perfomance`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/hr/employeeperformancemanagement");
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
<style>
td {
  font-size: 14px !important;
}
</style>
